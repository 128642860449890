import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import Catart from "./Catart"; // Import componenta Catart

function Categories() {
  const [isLoading, setIsLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [categoriesSource, setcategoriesSource] = useState("");
  const appState = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    if (Array.isArray(appState.config) && appState.config.length > 0) {
      const station = appState.config[appState.activeStation];
      if (station && station.categories) {
        setcategoriesSource(station.categories + "?per_page=100");
        setIsLoading(true); // Reinițializează starea de încărcare
      }
    }
  }, [appState.config, appState.activeStation]);

  useEffect(() => {
    if (!categoriesSource) {
      return;
    }

    const ourRequest = Axios.CancelToken.source();
    async function fetchCategories() {
      try {
        const response = await Axios.get(categoriesSource, {
          cancelToken: ourRequest.token,
        });
        setCategories(response.data);
        setIsLoading(false);
      } catch (e) {
        console.log(
          `Nu am putut descărca json-ul cu articole trending de la ${categoriesSource} sau requestul a fost întrerupt la cerere.`
        );
      }
    }

    fetchCategories();
    return () => {
      ourRequest.cancel();
    };
  }, [categoriesSource]);

  const handleMenuClick = () => {
    dispatch({ type: "stopTimer" });
  };

  const handleTrayContent = (station, catid, catName) => {
    return () => {
      dispatch({
        type: "setTrayContent",
        value: () => (
          <Catart station={station} catid={catid} catname={catName} />
        ),
      });
      handleMenuClick();
    };
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <section className="over-column">
        <div className="column-header">
          <h3>Categorii</h3>
          <p>{appState.config[appState.activeStation].station}</p>
        </div>
        <div className="card-body">
          {categories.map((category) => (
            <div className="card inline" key={category.id}>
              <a
                href="#!"
                onClick={handleTrayContent(
                  appState.activeStation,
                  category.id,
                  category.name
                )}
              >
                {category.name}
              </a>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default Categories;
