import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import StateContext from "../StateContext";

function CatArt(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [articlesCatArt, setCatArt] = useState([]);
  const [articlesCatArtSource, setArticlesCatArtSource] = useState("");
  const [selectedArticle, setSelectedArticle] = useState(null); // Stare pentru articolul selectat
  const appState = useContext(StateContext);

  const stationParam = props.station;
  const catidParam = props.catid;
  const catnameParam = props.catname;

  useEffect(() => {
    if (Array.isArray(appState.config) && appState.config.length > 0) {
      const station = appState.config[stationParam];
      if (station && station.article) {
        setArticlesCatArtSource(
          station.article + "?categories=" + catidParam + "&per_page=10"
        );
        console.log(
          "source: " +
          station.article +
          "?categories=" +
          catidParam +
          "&per_page=10"
        );
        setIsLoading(true); // Reinițializează starea de încărcare
      }
    }
  }, [appState.config, appState.activeStation]);

  useEffect(() => {
    if (!articlesCatArtSource) {
      return;
    }

    const ourRequest = Axios.CancelToken.source();
    async function fetchCatArt() {
      try {
        const response = await Axios.get(articlesCatArtSource, {
          cancelToken: ourRequest.token,
        });
        setCatArt(response.data);
        setIsLoading(false);
      } catch (e) {
        console.log(
          `Nu am putut descărca json-ul cu articole trending de la ${articlesCatArtSource} sau requestul a fost întrerupt la cerere.`
        );
      }
    }

    fetchCatArt();
    return () => {
      ourRequest.cancel();
    };
  }, [articlesCatArtSource]);

  const handleArticleClick = async (articleId) => {
    const station = appState.config[appState.activeStation];
    const articleUrl = `${station.article}/${articleId}`; // Construiți URL-ul articolului

    try {
      const response = await Axios.get(articleUrl);
      const articleData = response.data;
      const div = document.createElement("div");
      div.innerHTML = articleData.content.rendered;
      const listItems = div.querySelectorAll("ul li strong");
      const listItemsArray = Array.from(listItems).map((li) => li.innerHTML);

      setSelectedArticle({
        id: articleId,
        imageUrl:
          articleData.better_featured_image.media_details.sizes.medium_large
            .source_url,
        newLink: articleData.link,
        bumbi: listItemsArray,
      });
    } catch (e) {
      console.log(`Nu am putut descărca articolul de la ${articleUrl}`);
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <section className="over-column">
        <div className="column-header">
          <h3 className="font-weight-bolder text-danger text-gradient">
            Secțiune
          </h3>
          <p>{catnameParam}</p>
        </div>
        <div className="column-body">
          {articlesCatArt.map((article) => (
            <div
              className={
                selectedArticle && selectedArticle.id === article.id
                  ? "card opened"
                  : "card closed"
              }
              key={article.id}
            >
              <div className="card-header">
                <small>{appState.config[appState.activeStation].station}</small>
              </div>
              <div className="card-body">
                <div className="article-title">
                  <a
                    className="clickable-link"
                    onClick={() => handleArticleClick(article.id)}
                    href={
                      selectedArticle && selectedArticle.id === article.id
                        ? selectedArticle.newLink
                        : "#!"
                    }
                    target={
                      selectedArticle && selectedArticle.id === article.id
                        ? "_blank"
                        : "_self"
                    }
                    rel={
                      selectedArticle && selectedArticle.id === article.id
                        ? "noopener noreferrer"
                        : undefined
                    }
                  >
                    {article.title.rendered}
                  </a>
                  {selectedArticle && selectedArticle.id === article.id && (
                    <a
                      href={selectedArticle.newLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={selectedArticle.imageUrl}
                        alt="Selected Article"
                        className="img-fluid"
                      />
                      <ul className="bullets">
                        {selectedArticle.bumbi &&
                          selectedArticle.bumbi.map((item, index) => (
                            <li key={index}>{item}</li>
                          ))}
                      </ul>
                      <div className="read-more">Citește mai mult...</div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default CatArt;
