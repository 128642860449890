import React, {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  forwardRef,
} from "react";
import Slider from "react-slick";
import VideoPlayer from "./VideoPlayer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "video.js/dist/video-js.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} size="2x" />
    </div>
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} size="2x" />
    </div>
  );
};

const Vcarousel = forwardRef((props, ref) => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const [videoOptions, setVideoOptions] = useState([]);
  const [animate, setAnimate] = useState(false);
  const sliderRef = useRef(null);
  const videoPlayerRefs = useRef([]);

  useImperativeHandle(ref, () => ({
    slickGoTo: (slideIndex) => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(slideIndex);
      }
    },
  }));

  useEffect(() => {
    if (state.config.length > 0) {
      const newVideoOptions = state.config.map((station) => {
        let stream = "";
        if (state.orientation === "landscape") {
          stream = station.landscapeUrl;
        } else {
          stream = station.portraitUrl;
        }
        return {
          controls: false,
          muted: true,
          autoplay: true,
          fluid: true,
          playsinline: true,
          sources: [
            {
              src: stream,
            },
          ],
        };
      });
      setVideoOptions(newVideoOptions);
    }
  }, [state.config, state.orientation]);

  useEffect(() => {
    const currentSlide = sliderRef.current?.innerSlider?.state?.currentSlide;
    if (currentSlide !== undefined) {
      videoPlayerRefs.current.forEach((playerRef, index) => {
        if (playerRef && playerRef.player) {
          if (index === currentSlide) {
            playerRef.player.muted(state.muted);
          } else {
            playerRef.player.muted(true);
          }
        }
      });
    }
  }, [state.muted]);

  const handleAfterChange = (current) => {
    videoPlayerRefs.current.forEach((playerRef, index) => {
      if (playerRef && playerRef.player) {
        if (index === current) {
          playerRef.player.muted(state.muted);
        } else {
          playerRef.player.muted(true);
        }
      }
    });
    dispatch({ type: "setActiveStation", value: current });
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    afterChange: handleAfterChange,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    const slider = sliderRef.current;
    if (slider && slider.innerSlider) {
      setTimeout(() => {
        setAnimate(true);
        setTimeout(() => {
          setAnimate(false);
        }, 1000); // Durata animației
      }, 1000); // Întârziere înainte de a declanșa animația
    }
  }, [videoOptions]); // Asigură-te că aștepți până când videoOptions sunt setate

  if (videoOptions.length === 0) {
    return <div>Loading...</div>;
  } else {
    return (
      <section className="video-background">
        <div className="carousel-wrapper">
          <div className={`slider-container ${animate ? "animate-slide" : ""}`}>
            <Slider ref={sliderRef} {...sliderSettings}>
              {videoOptions.map((option, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <VideoPlayer
                    options={option}
                    onPlayerReady={(player) => {
                      videoPlayerRefs.current[index] = { player };
                      if (index === 0 && !state.muted) {
                        player.muted(false);
                      } else {
                        player.muted(true);
                      }
                    }}
                    onPlay={() => {}}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    );
  }
});

export default Vcarousel;
