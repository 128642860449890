import React, { useContext, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

import DispatchContext from "../DispatchContext";
import StateContext from "../StateContext";

const VideoPlayer = ({ options, onPlayerReady }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const dispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  useEffect(() => {
    if (videoRef.current) {
      if (!playerRef.current) {
        playerRef.current = videojs(videoRef.current, options, () => {
          if (onPlayerReady) {
            onPlayerReady(playerRef.current);
            dispatch({ type: "playerReady" });
          }
        });

        playerRef.current.on("playing", () => {
          dispatch({ type: "playerPlaying" });
        });
      }
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.src(options.sources);
      playerRef.current.muted(options.muted);
    }
  }, [options.sources, options.muted]);

  return (
    <div
      className={`video-wrapper ${appState.isPlayerPlaying ? "visible" : ""}`}
    >
      <div data-vjs-player>
        <video ref={videoRef} className="video-js"></video>
      </div>
    </div>
  );
};

export default VideoPlayer;
