import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVolumeHigh,
  faVolumeMute,
  faBars,
  faUsers,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import StateContext from "../StateContext";
import DispatchContext from "../DispatchContext";
import Menu from "./Menu";

function Nav() {
  const appState = useContext(StateContext);
  const dispatch = useContext(DispatchContext);

  const toggleMuted = () => {
    dispatch({ type: "toggleMute" });
  };

  const handleTray = () => {
    if (appState.isTrayVisible === false) {
      dispatch({ type: "setTrayVisible", value: true });
    } else {
      if (appState.trayContent !== Menu) {
        dispatch({ type: "setTrayContent", value: Menu });
      } else {
        dispatch({ type: "setTrayVisible", value: false });
      }
    }
  };

  return (
    <>
      <nav className="nav">
        <ul className="icons">
          <li className="hamburger">
            <a onClick={handleTray}>
              <FontAwesomeIcon
                icon={
                  appState.trayContent === null || appState.trayContent === Menu
                    ? faBars
                    : faChevronLeft
                }
              />
            </a>
          </li>
          <li className="empty"></li>
          <li className="notification">
            {appState.isTrayVisible === false && (
              <img src="/assets/img/logo-aleph.svg" alt="Aleph" />
            )}
          </li>
          <li className="volume">
            <a onClick={toggleMuted}>
              <FontAwesomeIcon
                icon={appState.muted ? faVolumeHigh : faVolumeMute}
              />
            </a>
          </li>
          <li className="users">
            <FontAwesomeIcon icon={faUsers} />
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Nav;
