import React, { useContext } from "react";
import { Link } from "react-router-dom";
import DispatchContext from "../DispatchContext.js";

import Trending from "./Trending.js";
import MostRead from "./MostRead.js";
import ForYou from "./ForYou.js";
import Categories from "./Categories.js";

function Menu() {
  const dispatch = useContext(DispatchContext);

  const handleMenuClick = () => {
    dispatch({ type: "stopTimer" });
  };

  const changeStation = (station) => {
    return () => {
      dispatch({ type: "setActiveStation", value: station });
      handleMenuClick();
    };
  };

  const handleTrayContent = (component) => {
    return () => {
      dispatch({ type: "setTrayContent", value: component });
      handleMenuClick();
    };
  };

  return (
    <div className="menu-container" onClick={handleMenuClick}>
      <div className="menu-column left initial">
        <ul>
          <li className="stronger">Live TV</li>
          <li className="pushed">
            <a href="#!" onClick={changeStation(0)}>
              Aleph News
            </a>
          </li>
          <li className="pushed">
            <a href="#!" onClick={changeStation(1)}>
              Aleph Business
            </a>
          </li>
        </ul>
      </div>
      <div className="menu-column right initial">
        <ul>
          <li>
            <Link to="#!" onClick={handleTrayContent(Trending)}>
              Trending
            </Link>
          </li>
          <li>
            <Link to="#!" onClick={handleTrayContent(MostRead)}>
              Most Read
            </Link>
          </li>
          <li>
            <Link to="#!" onClick={handleTrayContent(ForYou)}>
              For You
            </Link>
          </li>
          <li>
            <Link to="#!" onClick={handleTrayContent(Categories)}>
              Categorii
            </Link>
          </li>
        </ul>
      </div>
      <div className="logo">
        <img src="/assets/img/logo-aleph-rosu.png" alt="Logo Aleph" />
      </div>
      <div className="menu-column left initial">
        <ul>
          <li>
            <a href="https://alephnews.ro">Aleph News</a>
          </li>
          <li>
            <a href="https://alephbusiness.ro">Aleph Business</a>
          </li>
          <li>
            <a href="https://alephmentor.ro">Aleph Mentor</a>
          </li>
          <li>
            <a href="https://alephcult.ro">Aleph Cult</a>
          </li>
          <li>
            <a href="https://alephhealth.ro">Aleph Health</a>
          </li>
          <li>
            <a href="https://stiu.alephnews.ro">Știu</a>
          </li>
          <li>
            <a href="https://stiupecelumetraiesc.alephnews.ro">
              Știu pe ce lume trăiesc
            </a>
          </li>
        </ul>
      </div>
      <div className="menu-column right initial">
        <ul>
          <li>
            <a href="https://mediafax.ro">Mediafax</a>
          </li>
          <li>
            <a href="https://zf.ro">Ziarul Financiar</a>
          </li>
          <li>
            <a href="https://zfcorporate.ro">ZF Corporate</a>
          </li>
          <li>
            <a href="https://businessmagazin.ro">Business Magazin</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Menu;
