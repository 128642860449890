import React from "react";

function Notification({ payload }) {
  return (
    <>
      <div className="notif">
        <h3>{payload.header}</h3>
        <a className="notif-title" href={payload.link}>
          {payload.message}
        </a>
      </div>
    </>
  );
}

export default Notification;
