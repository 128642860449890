import React, { useContext, useEffect, useRef } from "react";
import DispatchContext from "../DispatchContext.js";
import StateContext from "../StateContext.js";
import Menu from "./Menu.js";

function Tray({ TrayContent }) {
  const dispatch = useContext(DispatchContext);
  const appState = useContext(StateContext);

  const trayRef = useRef(null);

  useEffect(() => {
    if (appState.trayContent === null) {
      dispatch({ type: "setTrayContent", value: Menu });
      trayRef.current.classList.add("initial");
    }
  }, []);

  useEffect(() => {
    if (appState.isPlayerPlaying && !appState.isTimerStopped) {
      const timerCloseInitialTray = setTimeout(() => {
        dispatch({ type: "setTrayVisible", value: false });
      }, 5000);
      const timerBeginTransparency = setTimeout(() => {
        if (trayRef.current) {
          trayRef.current.classList.remove("initial");
        }
      }, 2000);
      const menuBeginTransparency = setTimeout(() => {
        if (trayRef.current) {
          const divElements = trayRef.current.querySelectorAll('.menu-column');
          divElements.forEach((div) => {
            div.classList.remove("initial");
          });
        }
      }, 3000);
      return () => clearTimeout(timerCloseInitialTray, timerBeginTransparency, menuBeginTransparency);
    }
  }, [appState.isPlayerPlaying, appState.isTimerStopped, dispatch]);

  const renderTrayContent = () => {
    if (TrayContent) {
      if (typeof TrayContent === "function") {
        return <TrayContent />;
      }
      return <TrayContent />;
    }
    return null;
  };

  return (
    <div ref={trayRef} className={`tray ${appState.isTrayVisible ? "visible" : ""}`}>
      {renderTrayContent()}
    </div>
  );
}

export default Tray;
