import React, { useEffect, useRef, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { useImmerReducer } from "use-immer";
import io from "socket.io-client";

import Nav from "./components/Nav.js";
import Page from "./components/Page.js";
import Vcarousel from "./components/Vcarousel.js";
import Tray from "./components/Tray.js";
import Menu from "./components/Menu.js";
import Notification from "./components/Notification.js";

import StateContext from "./StateContext.js";
import DispatchContext from "./DispatchContext.js";

import "./style.css";

function App() {
  const initialState = {
    config: {},
    orientation: "landscape",
    activeEvent: { header: "", message: "", link: "" },
    activeStation: 0,
    muted: true,
    isPlayerPlaying: false,
    isTrayVisible: true,
    trayContent: null,
    isTimerStopped: false,
  };

  function appReducer(draft, action) {
    switch (action.type) {
      case "setConfig": {
        draft.config = action.payload;
        return;
      }
      case "setOrientation": {
        draft.orientation = action.value;
        return;
      }
      case "newEvent": {
        draft.activeEvent = action.value;
        return;
      }
      case "setActiveStation": {
        draft.activeStation = action.value;
        return;
      }
      case "toggleMute": {
        draft.muted = !draft.muted;
        return;
      }
      case "setTrayVisible": {
        draft.isTrayVisible = action.value;
        return;
      }
      case "playerPlaying": {
        draft.isPlayerPlaying = true;
        return;
      }
      case "setTrayContent": {
        draft.trayContent = action.value;
        return;
      }
      case "stopTimer": {
        draft.isTimerStopped = true;
        return;
      }
      case "resetTimer": {
        draft.isTimerStopped = false;
        return;
      }
      default:
        return draft;
    }
  }

  const [state, dispatch] = useImmerReducer(appReducer, initialState);
  const sliderRef = useRef(null);

  // Get config.json
  useEffect(() => {
    fetch("./config.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        let tmp = data.map((station) => ({ ...station }));

        const fetchPromises = tmp.map((station, i) =>
          fetch(station.designConfig)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
            })
            .then((config) => {
              tmp[i] = {
                ...tmp[i],
                portraitUrl: config[0].portraitUrl,
                landscapeUrl: config[0].landScapeUrl,
              };
            })
            .catch((error) => {
              console.error(
                `Error loading station config for ${station.name}:`,
                error
              );
            })
        );

        Promise.all(fetchPromises)
          .then(() => {
            dispatch({ type: "setConfig", payload: tmp });
          })
          .catch((error) => {
            console.error("Error loading all configs:", error);
          });
      })
      .catch((error) => {
        console.error("Error loading config:", error);
      });
  }, [dispatch]);

  // Set aspect ratio
  useEffect(() => {
    function adjustAspectRatio() {
      const aspectRatioDiv = document.querySelector(".aspect-ratio");
      const body = document.body;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      if (viewportWidth > viewportHeight) {
        dispatch({ type: "setOrientation", value: "landscape" });
        body.classList.remove("portrait");
        body.classList.add("landscape");
        const width = viewportHeight * (16 / 9);
        const height = viewportHeight;
        if (width > viewportWidth) {
          aspectRatioDiv.style.width = `${viewportWidth}px`;
          aspectRatioDiv.style.height = `${viewportWidth / (16 / 9)}px`;
        } else {
          aspectRatioDiv.style.width = `${width}px`;
          aspectRatioDiv.style.height = `${height}px`;
        }
      } else {
        dispatch({ type: "setOrientation", value: "portrait" });
        body.classList.remove("landscape");
        body.classList.add("portrait");
        const height = viewportWidth * (16 / 9);
        const width = viewportWidth;
        if (height > viewportHeight) {
          aspectRatioDiv.style.width = `${viewportHeight / (16 / 9)}px`;
          aspectRatioDiv.style.height = `${viewportHeight}px`;
        } else {
          aspectRatioDiv.style.width = `${width}px`;
          aspectRatioDiv.style.height = `${height}px`;
        }
      }

      aspectRatioDiv.style.position = "absolute";
      aspectRatioDiv.style.top = "50%";
      aspectRatioDiv.style.left = "50%";
      aspectRatioDiv.style.transform = "translate(-50%, -50%)";
    }

    window.addEventListener("resize", adjustAspectRatio);
    window.addEventListener("load", adjustAspectRatio);

    adjustAspectRatio();

    return () => {
      window.removeEventListener("resize", adjustAspectRatio);
      window.removeEventListener("load", adjustAspectRatio);
    };
  }, [dispatch]);

  // Stop timer on menu click
  const handleMenuClick = () => {
    dispatch({ type: "stopTimer" });
  };

  // Catch events from WS
  useEffect(() => {
    const socket = io(process.env.WS_ADDRESS);
    let header = "";
    let message = "";
    let link = "";
    socket.on("newsyncurl", (data) => {
      console.log(data);

      if (data.event === "App\\Events\\NunzioStoriesEvent") {
        header = "Citește pe alephnews.ro:";
        message = data.data.message.title;
        if (data.data.message.an_id != null) {
          link = "https://alephnews.ro/?p=" + data.data.message.an_id;
        } else {
          link = data.data.message.forced_link;
        }
      }

      if (data.event === "App\\Events\\NunzioAdsEvent") {
        header = "RECLAMĂ";
        message = "Vizitează clientul nostru";
        if (data.data.message.client != null) {
          message += data.data.message.client;
        }
        link = data.data.message.ad_url;
      }

      let payload = {
        header: header,
        message: message,
        link: link,
      };

      if (!state.isTrayVisible) {
        dispatch({
          type: "setTrayContent",
          value: () => <Notification payload={payload} />,
        });
        dispatch({ type: "setTrayVisible", value: true });

        handleMenuClick();

        setTimeout(() => {
          dispatch({ type: "setTrayVisible", value: false });
          dispatch({ type: "setTrayContent", value: Menu });
        }, 5000);
      } else {
        console.log("Ignoring event: ", payload);
      }
    });

    return () => {
      socket.off("newsyncurl").disconnect();
    };
  }, [state.isTrayVisible, dispatch]);

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.slickGoTo) {
      sliderRef.current.slickGoTo(state.activeStation);
    }
  }, [state.activeStation]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        <BrowserRouter>
          <Suspense fallback={<div>Se încarcă...</div>}>
            <Page>
              <Nav />
              <Tray TrayContent={state.trayContent} />
              <Vcarousel ref={sliderRef} />
            </Page>
          </Suspense>
        </BrowserRouter>
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

const container = document.getElementById("app");

if (!container._reactRootContainer) {
  const root = ReactDOM.createRoot(container);
  root.render(<App />);
  container._reactRootContainer = root;
} else {
  container._reactRootContainer.render(<App />);
}

if (module.hot) {
  module.hot.accept();
}

export default App;
